import {Injectable} from '@angular/core';
import {JsonService} from './json.service';
import {RequestEvent} from '../../common/event/requestEvent';
import {ResponseEvent} from '../../common/event/responseEvent';
import {LogUtils} from '../../common/utils/logUtils';
import {serverPaths} from '../../common/helpers/pathHelpers';
import {emailUtils} from '../../common/utils/emailUtils';
import {ManagedMail} from '../../common/models/user/managedMail';
import {UxComposite} from '../../common/models/ux/uxComposite';

@Injectable()
export class ReferralService {

  /**
   *
   * @param jsonService to having the servie method to call the API
   */
  constructor(private jsonService: JsonService) {
  }

  
  public referralSendEmailAddress(email): Promise<any> {
    if (emailUtils.validateEmail(email)) {
      let requestEvent = new RequestEvent();
      requestEvent.param = {email: email};
      return this.jsonService.json(serverPaths.referralSendEmail, requestEvent).catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return responseEvent;
      }).then((responseEvent: ResponseEvent) => {
        if (responseEvent && responseEvent.isSuccess()) {
          return Promise.resolve(responseEvent);
        } else {
          return Promise.reject(responseEvent);
        }
      });
    } else {
      return Promise.reject(false);
    }
  }  

  public referralCopyLink(): Promise<any> {

      let requestEvent = new RequestEvent();
      return this.jsonService.json(serverPaths.referralCopyLink, requestEvent).catch((responseEvent: ResponseEvent) => {
        LogUtils.error(responseEvent.rawResponse);
        return responseEvent;
      }).then((responseEvent: ResponseEvent) => {
        if (responseEvent && responseEvent.isSuccess()) {
          return Promise.resolve(responseEvent);
        } else {
          return Promise.reject(responseEvent);
        }
      });
  }  

  public referralLinkCustomer(hash: string, email: string): Promise<any> {
    if (emailUtils.validateEmail(email)) {
        let requestEvent = new RequestEvent();
        requestEvent.param = {hash: hash, email: email};
        return this.jsonService.json(serverPaths.verifyReferralLink, requestEvent).catch((responseEvent: ResponseEvent) => {
          LogUtils.error(responseEvent.rawResponse);
          return responseEvent;
        }).then((responseEvent: ResponseEvent) => {
          if (responseEvent && responseEvent.isSuccess()) {
            return Promise.resolve(responseEvent);
          } else {
            return Promise.reject(false);
          }
        });
      } else {
        return Promise.reject(false);
      }    
  }
}

