import {IndexField} from "../../decorators/database/indexField";
import {ModelBase} from "../modelBase";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {LogUtils} from "../../utils/logUtils";

@CollectionClass({name: "uxComponents"})
export class UxComponent extends ModelBase<UxComponent> {
  public static TYPE = {
    JSON: "JSON",
    content: "content",
  };

  @IndexField() public name;
  public description;
  public content;

  public serverOnly: boolean;

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  check(logFlag = true) {
    let flag = true;
    if (this.type === UxComponent.TYPE.JSON) {
      try {
        JSON.parse(this.content);
      } catch (e) {
        if (logFlag) {
          LogUtils.error(e);
        }
        flag = false;
      }
    }

    return flag;
  }

}

