import { Component, Inject, OnInit } from '@angular/core';
import { UxConfig } from '../../common/models/ux/uxConfig';
import { UxLayout } from '../../common/models/ux/uxLayout';
import { UxComponentCollection } from '../../common/models/ux/uxComponentCollection';
import { UxComponent } from '../../common/models/ux/uxComponent';
import { ModelBase } from '../../common/models/modelBase';
import { CrudService } from '../../clientCommon/services/crud.service';
import { LogUtils } from '../../common/utils/logUtils';
import { ArrayUtils } from '../../common/utils/arrayUtils';
import { collectionClassHelper } from '../../common/decorators/database/collectionClass';
import { SpinnerService } from '../../clientCommon/services/spinner.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { StorageService } from '../../clientCommon/services/storage.service';
import { inputUtils } from '../../clientCommon/utils/inputUtils';
import { ResponseEvent } from '../../common/event/responseEvent';
import { JsonService } from '../../clientCommon/services';
import { CrudHelperSearchOptions, CrudHelperService } from '../services/crudHelper.service';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { adminPaths } from '../../common/helpers/pathHelpers';
import { UxcBuildModalDialog } from './uxcBuildModal.component';
import { BehaviorSubject } from 'rxjs';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';

export type UxcBuildStatus = 'building' | 'success' | 'failed';

type BuildStatusSubjectValues = {
  startTimestamp: number;
  endTimestamp: number;
  status: UxcBuildStatus;
};

@Component({
  selector: 'app-delete-confirm-modal',
  templateUrl: 'delete-confirm-modal.html',
})
export class DeleteConfirmDialog {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data: boolean
  ) {}
}

@Component({
  templateUrl: 'uxcFullMgmt.component.html',
  styleUrls: ['./uxcFullMgmt.component.scss'],
})

export class UxcFullMgmtComponent implements OnInit {
  uxHistory: Record<string, Array<any>> = { 
    uxConfigs: [], 
    uxLayouts: [], 
    uxComponents: [], 
    uxComponentCollections: [],
  };
  selectedId: string;
  selectedType: string;

  addFlag = false;
  newUxc: UxConfig = new UxConfig();
  newUxl: UxLayout = new UxLayout();
  newUxcc: UxComponentCollection = new UxComponentCollection();
  newUxComponent: UxComponent = new UxComponent();

  uxComponentTypes: { key: string, value: string }[] = [];
  alertDuration = 5000;

  SLOT_TYPES = UxConfig.SLOT_TYPES;
  slotTypes = ArrayUtils.toArray(UxConfig.SLOT_TYPES);
  fullContentFlag = false;
  idRegex = new RegExp('^([0-9a-f]{24}|[|])*$');
  dragIndex = -1;
  tempId = 0;
  ModelBase = ModelBase;

  uxDetailTypes = ['uxLayouts', 'uxComponentCollections', 'uxComponents'];

  newUxcType = 'uxconfig';
  uxCompDownloadFlag = false;
  draftUxc = {};
  draftUniqueId = 0;

  results: {
    uxConfigs: UxConfig[]
    uxLayouts: UxLayout[]
    uxComponentCollections: UxComponentCollection[]
    uxComponents: UxComponent[]
  } = {
      uxConfigs: [],
      uxLayouts: [],
      uxComponentCollections: [],
      uxComponents: [],
    };

  searchOptions: CrudHelperSearchOptions = {
    key: 'adminUxcSearchOptionFilters',
    filters: {
      uxConfigs: {
        className: collectionClassHelper.getCollectionName(UxConfig),
        fields: ['_id', 'name'],
        value: '',
      },
      uxLayouts: {
        className: collectionClassHelper.getCollectionName(UxLayout),
        fields: ['_id', 'name'],
        value: '',
      },
      uxComponentCollections: {
        className: collectionClassHelper.getCollectionName(UxComponentCollection),
        fields: ['_id', 'name'],
        value: '',
      },
      uxComponents: {
        className: collectionClassHelper.getCollectionName(UxComponent),
        fields: ['_id', 'name'],
        value: '',
        additionalFields: {
          serverOnly: null,
        },
      },
    },
  };

  jsonToDownload = [];
  adminPaths = adminPaths;

  uxcRemovedStatus = {};
  uxlRemovedStatus = {};
  uxccUxccRemovedStatus = {};
  uxccUxcompRemovedStatus = {};

  buildStatusSubject = new BehaviorSubject<Record<string, BuildStatusSubjectValues>>({});
  
  dialogOpened = false;
  uxcDownloadIds: string[] = [];
  uxcUpdateIds = [];
  uxcBuildParallelSize = 36;

  constructor(
    private crudService: CrudService,
    private crudHelperService: CrudHelperService,
    private snackBar: MatSnackBar,
    private storageService: StorageService,
    private jsonService: JsonService,
    private spinnerService: SpinnerService,
    private dialog: MatDialog,
  ) {
    this.uxComponentTypes = ArrayUtils.toArray(UxComponent.TYPE);
  }

  ngOnInit() {
    this.newUxComponent.draft.type = UxComponent.TYPE.JSON;

    const filters = this.storageService.get(this.searchOptions.key);

    if (filters) {
      this.searchOptions.filters = filters;
    }
  }

  addUxc() {
    if (this.newUxc.draft.name) {
      this.newUxc.draft.status = ModelBase.STATUS.active;
      this.newUxc.draft.removeUnusedSlotTypes();
      this.newUxc.draft.removeRemoved();
      return this.crudHelperService.add(this.newUxc, this.alertDuration, this.searchOptions, this.results).then(() => {
        this.newUxc = new UxConfig();
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxc.draft.name, 'Error', { duration: this.alertDuration });
    }
  }

  addUxl() {
    if (this.newUxl.draft.name) {
      this.newUxl.draft.status = ModelBase.STATUS.active;
      return this.crudHelperService.add(this.newUxl, this.alertDuration, this.searchOptions, this.results).then(() => {
        this.newUxl = new UxLayout();
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxl.draft.name, 'Error', { duration: this.alertDuration });
    }
  }

  addUxcc() {
    if (this.newUxcc.draft.name) {
      this.newUxcc.draft.status = ModelBase.STATUS.active;
      return this.crudHelperService.add(this.newUxcc, this.alertDuration, this.searchOptions, this.results).then((id) => {
        this.draftUxc = {
          ...this.draftUxc,
          [id]: this.draftUniqueId,
        };
        this.jsonToDownload.push({
          uxtype: 'uxcc',
          action: 'Created',
          data: {
            name: this.newUxcc.draft.name,
            description: this.newUxcc.draft.description || '',
            uxccs: (this.newUxcc.draft.childUxccIds || []).map(id => {
              return {
                uniqueId: this.draftUxc[id],
              }
            }),
            uxcomps: (this.newUxcc.draft.uxComponentIds || []).map(id => {
              if (this.draftUxc[id]) {
                return {
                  uniqueId: this.draftUxc[id],
                }
              }
              return {
                id
              }
            }),
            status: this.newUxcc.draft.status || ModelBase.STATUS.active,
          },
          uniqueId: this.draftUniqueId,
        });
        this.draftUniqueId++;
        this.newUxcc = new UxComponentCollection();
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxcc.draft.name, 'Error', { duration: this.alertDuration });
    }
  }

  addUxComponent() {
    if (this.newUxComponent.draft.name && (this.newUxComponent.getToggle('uncheck') || this.newUxComponent.draft.check())) {
      this.newUxComponent.draft.status = ModelBase.STATUS.active;
      return this.crudHelperService.add(this.newUxComponent, this.alertDuration, this.searchOptions, this.results).then((id) => {
        this.draftUxc = {
          ...this.draftUxc,
          [id]: this.draftUniqueId,
        };

        const { name, description, serverOnly, type, content, status } = this.newUxComponent.draft;
        this.jsonToDownload.push({
          uxtype: 'uxcomp',
          action: 'Created',
          data: {
            name,
            serverOnly,
            type,
            content,
            status: status || ModelBase.STATUS.active,
            description: description || '',
          },
          uniqueId: this.draftUniqueId,
        });
        this.draftUniqueId++;
        this.newUxComponent = new UxComponent();
        this.newUxComponent.draft.type = UxComponent.TYPE.JSON;
      });
    } else {
      this.snackBar.open('Error adding ' + this.newUxComponent.draft.name, 'Error', { duration: this.alertDuration });
    }
  }

  search(): Promise<any> {
    this.uxHistory = { uxConfigs: [], uxLayouts: [], uxComponents: [], uxComponentCollections: [] };
    this.searchOptions.sort = [["_id", "asc"]];
    return this.crudHelperService.search(this.searchOptions, this.results).then(() => {
      // nothing
      this.uxcDownloadIds = [];
      this.results.uxComponentCollections.forEach((uxcc) => this.uxcDownloadIds.push(uxcc._id));
      this.results.uxComponents.forEach((uxcomp) => this.uxcDownloadIds.push(uxcomp._id));
    }).catch((e) => {
      LogUtils.error(e);
    });
  }

  update(obj: ModelBase<any>) {
    let uxType = '';

    try {
      if (obj instanceof UxConfig) {
        ArrayUtils.removeEmpty(obj.draft.uxLayoutIds);
        obj.draft.uxLayoutIds = obj.draft.uxLayoutIds.filter(id => !this.uxcRemovedStatus[obj._id].includes(id));
        obj.draft.removeUnusedSlotTypes();
        obj.draft.removeRemoved();
        delete this.uxcRemovedStatus[obj._id];
        uxType = "uxConfigs"
      } else if (obj instanceof UxLayout) {
        obj.draft.uxComponentCollectionIds = obj.draft.uxComponentCollectionIds.filter(id => !this.uxlRemovedStatus[obj._id].includes(id));
        ArrayUtils.removeEmpty(obj.draft.uxComponentCollectionIds);
        uxType = "uxLayouts"
      } else if (obj instanceof UxComponentCollection) {
        const { childUxccs, uxComponents, name, status, description } = obj.draft;
        this.jsonToDownload.push({
          uxtype: 'uxcc',
          action: 'Updated',
          data: {
            _id: obj._id,
            name,
            description,
            originalRevisionId: obj,
            status: status || ModelBase.STATUS.active,
            uxccs: childUxccs?.map(uxcc => ({ id: uxcc._id, name: uxcc.name })) || [],
            uxcomps: uxComponents?.map(uxcomp => ({ id: uxcomp._id, name: uxcomp.name })) || [],
          }
        });
        obj.draft.childUxccIds = obj.draft.childUxccIds.filter(id => !this.uxccUxccRemovedStatus[obj._id].includes(id));
        obj.draft.uxComponentIds = obj.draft.uxComponentIds.filter(id => !this.uxccUxcompRemovedStatus[obj._id].includes(id));
        ArrayUtils.removeEmpty(obj.draft.childUxccIds);
        ArrayUtils.removeEmpty(obj.draft.uxComponentIds);
        uxType = "uxComponentCollections"
      } else if (obj instanceof UxComponent) {
        // Nothing to do
        if ((!obj.getToggle('uncheck')) && (!obj.draft.check())) {
          this.snackBar.open('Error updating ' + obj.draft.name, 'Error', { duration: this.alertDuration });
          return;
        }
        const { name, description, serverOnly, type, content, status } = obj.draft;
        this.jsonToDownload.push({
          uxtype: 'uxcomp',
          action: 'Updated',
          data: {
            _id: obj._id,
            name,
            description,
            serverOnly,
            type,
            content,
            originalRevisionId: obj,
            status: status || ModelBase.STATUS.active,
          },
        });
        uxType = "uxComponents"
      }
      return this.crudHelperService.update(obj, this.alertDuration, this.searchOptions, this.results).then(() => {
        let uxAlreadyOpened = this.isReferenceAvailable(this.uxHistory[uxType], obj._id)
        if (uxAlreadyOpened) {
          this.uxHistory = { uxConfigs: [], uxLayouts: [], uxComponents: [], uxComponentCollections: [] };
          this.findUxHistory(obj._id, uxType);
        }
      });
    } catch (e) {
      LogUtils.error(e);
    }
  }

  openDeleteModal(obj: ModelBase<any>) {
    const dialogRef = this.dialog.open(DeleteConfirmDialog, {
      width: '80vw'
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result === true) {
        this.delete(obj);
      }
    });
  }

  delete(obj: ModelBase<any>) {
    try {
      this.toggleStatus(obj);
      return this.crudHelperService.update(obj, this.alertDuration, this.searchOptions, this.results);
    } catch (e) {
      LogUtils.error(e);
    }
  }

  addItemToArray(array: any[], form) {
    if (form.value) {
      array.push(form.value);
      form.value = null;
    }
  }

  removeItemInArray(array: any[], index) {
    array.splice(index, 1);
  }

  toggleStatus(object: ModelBase<any>) {
    if (object.draft.status === ModelBase.STATUS.active) {
      object.draft.status = ModelBase.STATUS.removed;
    } else {
      object.draft.status = ModelBase.STATUS.active;
    }
  }

  copyId(obj) {
    return this.crudHelperService.copyId(obj, this.alertDuration);
  }

  copyName(obj) {
    return this.crudHelperService.copyName(obj, this.alertDuration);
  }

  isWeightedUxLayout(uxc: UxConfig, uxlId) {
    return uxc.slotTypes[uxlId] && uxc.slotTypes[uxlId].slotType === UxConfig.SLOT_TYPES.default;
  }

  keyDownEvent(event) {
    if (inputUtils.isEnterEvent(event)) {
      return this.search();
    }
  }

  buildUxComposites() {
    const uxcIds = [];
    this.results.uxConfigs.forEach((uxConfig) => {
      uxcIds.push(uxConfig._id);
    });

    if (!this.dialogOpened) {
      this.dialogOpened = true;
      const dialogRef = this.dialog.open(UxcBuildModalDialog, {
        width: '80vw',
        data: { statusSubject: this.buildStatusSubject }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.buildStatusSubject.next({});
        this.dialogOpened = false;
      });
      const data = {};
      uxcIds.forEach((uxcId) => {
        data[uxcId] = {
          startTimestamp: 0,
          endTimestamp: 0,
          status: 'building',
        };
      });
      this.buildStatusSubject.next(data);
    }

    this.spinnerService.spin();
    return Promise.resolve().then(() => {
      const promises = [];

      for (let i = 0; i < this.uxcBuildParallelSize; i++) {
        promises.push(this.executeBuildUxComposite(uxcIds));
      }

      return Promise.all(promises);
    }).catch((e) => {
      LogUtils.error(e, uxcIds);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  executeBuildUxComposite(uxcIds: string[]) {
    return Promise.resolve().then(() => {
      if (uxcIds.length) {
        const uxcId = uxcIds.pop();
        return this.buildUxComposite(uxcId, false).then(() => {
          this.snackBar.open('UXC Building Process. In queue:' + uxcIds.length, 'Process', { duration: this.alertDuration });
          return this.executeBuildUxComposite(uxcIds);
        }).then(() => {
          this.buildStatusSubject.next({
            ...this.buildStatusSubject.getValue(),
            [uxcId]: {
              ...this.buildStatusSubject.getValue()[uxcId],
              endTimestamp: new Date().getTime(),
              status: 'success'
            }
          });
        }).catch((e) => {
          this.buildStatusSubject.next({
            ...this.buildStatusSubject.getValue(),
            [uxcId]: {
              ...this.buildStatusSubject.getValue()[uxcId],
              endTimestamp: new Date().getTime(),
              status: 'failed'
            }
          });
        }).then(() => {
          return this.executeBuildUxComposite(uxcIds).then(() => {
          });
        });
      }
    });
  }

  runBuild(uxcId, logFlag) {
    this.buildUxComposite(uxcId, logFlag);
  }

  buildUxComposite(uxcId, logFlag) {
    if (!this.dialogOpened) {
      this.dialogOpened = true;
      const dialogRef = this.dialog.open(UxcBuildModalDialog, {
        width: '80vw',
        data: { statusSubject: this.buildStatusSubject }
      });
      dialogRef.afterClosed().subscribe(() => {
        this.buildStatusSubject.next({});
        this.dialogOpened = false;
      });
    }
    this.buildStatusSubject.next({
      ...this.buildStatusSubject.getValue(),
      [uxcId]: {
        startTimestamp: new Date().getTime(),
        endTimestamp: 0,
        status: 'building'
      }
    });
    const url = '/api/manage/admin/build/uxComposites';
    const params = { uxcIds: [uxcId] };

    return this.jsonService.json(url, params).then((responseEvent: ResponseEvent) => {
      if (logFlag) {
        this.snackBar.open('UXC build done:' + uxcId, 'Build', { duration: this.alertDuration });
        LogUtils.info(uxcId, responseEvent.data);
      }
      this.buildStatusSubject.next({
        ...this.buildStatusSubject.getValue(),
        [uxcId]: {
          ...this.buildStatusSubject.getValue()[uxcId],
          endTimestamp: new Date().getTime(),
          status: 'success'
        }
      });
      return;
    }).catch((e) => {
      if (logFlag) {
        this.snackBar.open('UXC build failed:' + uxcId, 'Fail', { duration: this.alertDuration });
      }
      LogUtils.error(uxcId, e);
      this.buildStatusSubject.next({
        ...this.buildStatusSubject.getValue(),
        [uxcId]: {
          ...this.buildStatusSubject.getValue()[uxcId],
          endTimestamp: new Date().getTime(),
          status: 'failed'
        }
      });
      return Promise.reject(false);
    });
  }

  attachUxDetail(uxDetails) {
    LogUtils.info('UxDetails', uxDetails);

    if (uxDetails && uxDetails.length) {
      uxDetails.forEach((uxDetail) => {
        let flag = false;
        this.uxDetailTypes.some((type) => {
          if (this.results[type] && this.results[type].length) {
            this.results[type].some((ux: ModelBase<any>) => {
              if (ux._id === uxDetail.baseId) {
                ux.tempClient.uxDetail = uxDetail;
                flag = true;
                return flag;
              }
            });
          }
          return flag;
        });
      });
    }
  }

  findUxDetail(uxId) {
    const url = '/api/manage/admin/find/uxDetails';
    const params = { uxIds: [uxId] };

    this.spinnerService.spin();
    return this.jsonService.json(url, params).then((responseEvent: ResponseEvent) => {
      this.attachUxDetail(responseEvent.data.uxDetails);
      this.snackBar.open('Find UX detail done:' + uxId, 'Find', { duration: this.alertDuration });
    }).catch((e) => {
      this.snackBar.open('Find UX detail failed:' + uxId, 'Fail', { duration: this.alertDuration });
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  findOrphanUx() {
    const url = '/api/manage/admin/find/uxDetails';
    const uxIds = [];
    const params = { uxIds: uxIds, type: 'orphanCheck' };

    this.uxDetailTypes.forEach((type) => {
      this.results[type].forEach((ux: ModelBase<any>) => {
        uxIds.push(ux._id);
      });
    });

    this.spinnerService.spin();
    return this.jsonService.json(url, params).then((responseEvent: ResponseEvent) => {
      this.attachUxDetail(responseEvent.data.uxDetails);
      this.snackBar.open('Find orphan done', 'Find', { duration: this.alertDuration });
    }).catch((e) => {
      this.snackBar.open('Find orphan failed', 'Fail', { duration: this.alertDuration });
      LogUtils.error(e);
    }).then(() => {
      this.spinnerService.unspin();
    });
  }

  previewHtml(uxcontent: string) {
    const win = window.open();
    win.document.write(uxcontent);
    win.focus();
  }

  duplicate(obj: ModelBase<any>) {
    const newObj = new (obj.constructor as any)(obj.getSaveSet());
    newObj.draft.name = 'Copy of ' + newObj.draft.name;
    return this.crudHelperService.add(newObj, this.alertDuration, this.searchOptions, this.results);
  }

  dragStart(event, array: [], index) {
    this.dragIndex = index;
  }

  dragOver(event, array: [], index) {
    event.preventDefault();
  }

  drop(event, array: [], index) {
    if (index >= 0 && this.dragIndex >= 0) {
      const swap = array[this.dragIndex];
      array.splice(this.dragIndex, 1);
      array.splice(index, 0, swap);
    }
  }

  dragEnd(event, array: [], index) {
    this.dragIndex = -1;
  }

  isOrphan(modelBase: ModelBase<any>) {
    return modelBase.tempClient.uxDetail && modelBase.tempClient.uxDetail.minDepth === 0;
  }

  hasDetail(modelBase: ModelBase<any>) {
    return modelBase.tempClient.uxDetail && (!modelBase.tempClient.uxDetail.type);
  }

  // Finds  UX history of the selected item
  findUxHistory(id, referenceCollection) {
    const url = `/api/manage/admin/findHistory/${id}`;
    this.spinnerService.spin();
    return this.jsonService
      .json(url, {})
      .then((responseEvent: ResponseEvent) => {
        this.selectedId = id;
        this.selectedType = referenceCollection;
        let uxAlreadyOpened = this.isReferenceAvailable(this.uxHistory[referenceCollection], id)
        if (!uxAlreadyOpened) {
          this.uxHistory[referenceCollection].push(responseEvent.data)
        }
      })
      .catch((e) => {
        LogUtils.error(e);
      })
      .then(() => {
        this.spinnerService.unspin();
      });
  }

  hasHistory(type, id) {
    return (this.uxHistory[type] && this.uxHistory[type].length > 0 && this.isReferenceAvailable(this.uxHistory[type], id))
  }

  isReferenceAvailable(arr, id) {
    return arr.some(item => item[0].referenceId && item[0].referenceId == id)
  }

  isOrphanCheckable() {
    let flag = false;

    this.uxDetailTypes.some((type) => {
      this.results[type].some((ux: ModelBase<any>) => {
        flag = true;
        return flag;
      });
    });
    return flag;
  }

  download() {
    const convertedBase64arr = this.jsonToDownload.map(obj => {
      if (obj.uxtype === 'uxcomp') {
        return {
          ...obj,
          data: {
            ...obj.data,
            name: btoa(unescape(encodeURIComponent(obj.data.name))),
            description: btoa(unescape(encodeURIComponent(obj.data.description))),
            content: btoa(unescape(encodeURIComponent(obj.data.content))),
            currentModelRevisionId: obj?.data?.originalRevisionId?.currentModelRevisionId
            //originalRevisionId: obj?.data?.originalRevisionId?.originalRevisionId || obj?.data?.originalRevisionId?.currentModelRevisionId
          }
        };
      }

      return {
        ...obj,
        data: {
          ...obj.data,
          name: btoa(unescape(encodeURIComponent(obj.data.name))),
          description: btoa(unescape(encodeURIComponent(obj.data.description))),
          currentModelRevisionId: obj?.data?.originalRevisionId?.currentModelRevisionId
          // originalRevisionId: obj?.data?.originalRevisionId?.originalRevisionId || obj?.data?.originalRevisionId?.currentModelRevisionId
        }
      };
    });
    const json = '[\r\n' + convertedBase64arr.map(c => JSON.stringify(c)).join(',\r\n') + '\r\n]';

    const blob = [json];
    const blobDownload = new Blob(blob, { type: 'text/plain;charset=utf-8' });
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blobDownload);
    const a = document.createElement('a');
    a.download = 'UXC-UXCC.txt';
    a.href = link;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  toggleEdit(obj: ModelBase<any>) {
    obj.tempClient.edit = !obj.tempClient.edit;
    if (obj.tempClient.edit) {
      if (obj instanceof UxConfig) {
        this.uxcRemovedStatus[obj._id] = [];
      } else if (obj instanceof UxLayout) {
        this.uxlRemovedStatus[obj._id] = [];
      } else if (obj instanceof UxComponentCollection) {
        this.uxccUxccRemovedStatus[obj._id] = [];
        this.uxccUxcompRemovedStatus[obj._id] = [];
      }
    } else {
      if (obj instanceof UxConfig) {
        delete this.uxcRemovedStatus[obj._id];
      } else if (obj instanceof UxLayout) {
        delete this.uxlRemovedStatus[obj._id];
      } else if (obj instanceof UxComponentCollection) {
        delete this.uxccUxccRemovedStatus[obj._id];
        delete this.uxccUxcompRemovedStatus[obj._id];
      }
    }
  }

  removeTempFromArray(obj: ModelBase<any>, index, type?: 'uxcc' | 'uxcomp') {
    if (obj instanceof UxConfig) {
      this.uxcRemovedStatus[obj._id].push(obj.draft.uxLayoutIds[index]);
    } else if (obj instanceof UxLayout) {
      this.uxlRemovedStatus[obj._id].push(obj.draft.uxComponentCollectionIds[index]);
    } else if (obj instanceof UxComponentCollection) {
      if (type === 'uxcc') {
        this.uxccUxccRemovedStatus[obj._id].push(obj.draft.childUxccIds[index]);
      } else {
        this.uxccUxcompRemovedStatus[obj._id].push(obj.draft.uxComponentIds[index]);
      }
    }
  }

  cancelRemoveTempFromArray(obj: ModelBase<any>, index, type?: 'uxcc' | 'uxcomp') {
    if (obj instanceof UxConfig) {
      this.uxcRemovedStatus[obj._id] = this.uxcRemovedStatus[obj._id].filter(id => id !== obj.draft.uxLayoutIds[index]);
    } else if (obj instanceof UxLayout) {
      this.uxlRemovedStatus[obj._id] = this.uxlRemovedStatus[obj._id].filter(id => id !== obj.draft.uxComponentCollectionIds[index]);
    } else if (obj instanceof UxComponentCollection) {
      if (type === 'uxcc') {
        this.uxccUxccRemovedStatus[obj._id] = this.uxccUxccRemovedStatus[obj._id].filter(id => id !== obj.draft.childUxccIds[index]);
      } else {
        this.uxccUxcompRemovedStatus[obj._id] = this.uxccUxcompRemovedStatus[obj._id].filter(id => id !== obj.draft.uxComponentIds[index]);
      }
    }
  }

  isTempRemoved(obj: ModelBase<any>, index, type?: 'uxcc' | 'uxcomp') {
    if (obj instanceof UxConfig) {
      return this.uxcRemovedStatus[obj._id]?.includes(obj.draft.uxLayoutIds[index]);
    } else if (obj instanceof UxLayout) {
      return this.uxlRemovedStatus[obj._id]?.includes(obj.draft.uxComponentCollectionIds[index]);
    } else if (obj instanceof UxComponentCollection) {
      if (type === 'uxcc') {
        return this.uxccUxccRemovedStatus[obj._id]?.includes(obj.draft.childUxccIds[index]);
      } else {
        return this.uxccUxcompRemovedStatus[obj._id]?.includes(obj.draft.uxComponentIds[index]);
      }
    }
  }

  isTempEdited(obj: ModelBase<any>) {
    if (obj instanceof UxConfig) {
      return this.uxcRemovedStatus[obj._id].length > 0;
    } else if (obj instanceof UxLayout) {
      return this.uxlRemovedStatus[obj._id].length > 0;
    } else if (obj instanceof UxComponentCollection) {
      return this.uxccUxccRemovedStatus[obj._id].length > 0 || this.uxccUxcompRemovedStatus[obj._id].length > 0;
    }
  }

  isNewItem(arr: any[], id) {
    return !arr.includes(id);
  }

  createFromSearched() {
    if (this.uxcDownloadIds.length === 0) {
      this.snackBar.open('No Uxcomp or Uxcc is available to be downloaded. Please search one or more.', 'Error', { duration: this.alertDuration });
      return;
    }
    let uniqueId = 0;
    const originalUxcompsArray = this.results.uxComponents
      .filter((uxcomp) => this.uxcDownloadIds.includes(uxcomp._id))
      .map((uxcomp) => ({
        uxtype: "uxcomp",
        action: this.uxcUpdateIds.includes(uxcomp._id) ? "Updated" : "Created",
        data: {
          _id: this.uxcUpdateIds.includes(uxcomp._id) ? uxcomp._id : undefined,
          name: btoa(unescape(encodeURIComponent(uxcomp.name))),
          description: btoa(unescape(encodeURIComponent(uxcomp.description))),
          type: uxcomp.type,
          content: btoa(unescape(encodeURIComponent(uxcomp.content))),
          status: "active",
          serverOnly: uxcomp.serverOnly,
        },
        uniqueId: uniqueId++,
      }));
    let childUxcompsArray = [];
    let uxccArray = [];
    this.results.uxComponentCollections.forEach((uxcc) => {
      const uxCompArray = uxcc.uxComponents.map((uxcomp) => ({
        uxtype: "uxcomp",
        action: this.uxCompDownloadFlag ? "Updated" : "Created",
        data: {
          _id: this.uxCompDownloadFlag ? uxcomp._id : undefined,
          name: btoa(unescape(encodeURIComponent(uxcomp.name))),
          description: btoa(unescape(encodeURIComponent(uxcomp.description))),
          type: uxcomp.type,
          content: btoa(unescape(encodeURIComponent(uxcomp.content))),
          status: "active",
          serverOnly: uxcomp.serverOnly,
        },
        uniqueId: uniqueId++,
      }));
      uxccArray.push({
        uxtype: "uxcc",
        action: this.uxCompDownloadFlag ? "Updated" : "Created",
        data: {
          _id: this.uxCompDownloadFlag ? uxcc._id : undefined,
          name: btoa(unescape(encodeURIComponent(uxcc.name))),
          description: btoa(unescape(encodeURIComponent(uxcc.description))),
          uxccs: uxcc.childUxccIds.map((id) => ({ id: id })),
          uxcomps: this.uxCompDownloadFlag ?
            uxcc.childUxccIds.map((id) => ({ id: id }))
            : uxCompArray.map((uxc) => ({ uniqueId: uxc.uniqueId }))
        },
        uniqueId: uniqueId++,
      });
      childUxcompsArray.push(...uxCompArray);
    })

    const downloadArray = [...originalUxcompsArray, ...childUxcompsArray, ...uxccArray];
    const json = '[\r\n' + downloadArray.map(c => JSON.stringify(c)).join(',\r\n') + '\r\n]';

    const blob = [json];
    const blobDownload = new Blob(blob, { type: 'text/plain;charset=utf-8' });
    const url = window.URL || window.webkitURL;
    const link = url.createObjectURL(blobDownload);
    const a = document.createElement('a');
    a.download = 'UXC-UXCC.txt';
    a.href = link;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  isDownloadable(obj: ModelBase<any>) {
    return this.uxcDownloadIds.includes(obj._id);
  }

  toggleDownloadFlag(obj: ModelBase<any>) {
    if (this.uxcDownloadIds.includes(obj._id)) {
      this.uxcDownloadIds = this.uxcDownloadIds.filter((i) => i !== obj._id);
    } else {
      this.uxcDownloadIds.push(obj._id);
    }
  }

  toggleCreateUpdateUxcomp(toUpdate: MatSlideToggleChange, obj: ModelBase<any>) {
    if (this.uxcUpdateIds.includes(obj._id)) {
      this.uxcUpdateIds = this.uxcUpdateIds.filter((i) => i !== obj._id);
    } else {
      this.uxcUpdateIds.push(obj._id);
    }
  }

  toggleCreateUpdate() {
    if (this.uxCompDownloadFlag) {
      this.uxcUpdateIds = this.results.uxComponents.map(r => r._id)
      return;
    }
    this.uxcUpdateIds = []
  }

  isAllDownloadable() {
    return this.uxcDownloadIds.length === (this.results.uxComponentCollections.length + this.results.uxComponents.length);
  }

  toggleAllDownload() {
    if (this.isAllDownloadable()) {
      this.uxcDownloadIds = [];
    } else {
      this.uxcDownloadIds = [
        ...this.results.uxComponentCollections.map((uxcc) => uxcc._id),
        ...this.results.uxComponents.map((uxcomp) => uxcomp._id),
      ];
    }
  }

  checkServerOnly(state) {
    if (!this.searchOptions.filters.uxComponents?.additionalFields) {
      this.searchOptions.filters.uxComponents['additionalFields'] = {};
    }

    if (!this.searchOptions.filters.uxComponents.additionalFields?.serverOnly) {
      this.searchOptions.filters.uxComponents.additionalFields['serverOnly'] = null;
    }

    switch (state) {
      case true:
        this.searchOptions.filters.uxComponents.additionalFields.serverOnly = false;
        break;
      case false:
        this.searchOptions.filters.uxComponents.additionalFields.serverOnly = null;
          break;
      default:
        this.searchOptions.filters.uxComponents.additionalFields.serverOnly = true;
        break;
    }
  }
}
