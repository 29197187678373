import { Component, Inject, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';

import { CommerceOrder } from '../../../common/models/commerce/commerceOrder';
import { CommerceOffer } from '../../../common/models/commerce/commerceOffer';
import { CommercePayment } from '../../../common/models/commerce/commercePayment';
import { CommerceTransaction } from '../../../common/models/commerce/commerceTransaction';
import { User } from '../../../common/models/user/user';
import { CommerceToken } from '../../../common/models/commerce/commerceToken';
import { custom } from '../../custom/custom.class';
import { CreditCardInputHelper } from '../../../clientCommon/helper/creditCardInputHelper';
import { Note } from 'src/common/models/note';
import {UxComposite} from '../../../common/models/ux/uxComposite';
import { UntypedFormBuilder, UntypedFormGroup,UntypedFormControl } from '@angular/forms';
import { serverPaths } from 'src/common/helpers/pathHelpers';
import { LogUtils } from 'src/common/utils/logUtils';
import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import * as moment from "moment";
import { ServiceHelperService } from 'src/clientCommon/services/serviceHelper.service';
import { HttpEvent } from '@angular/common/http';
import { CdkDragDrop, moveItemInArray, transferArrayItem } from '@angular/cdk/drag-drop';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';


@Component({
  selector: "add-note-dialog",
  templateUrl: "addNoteDialog.component.html",
})
export class AddNoteDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public note: string) { }
}

@Component({
  selector: "view-uxhistory-search-dialog",
  templateUrl: "viewUxHistorySearchDialog.component.html",
})
export class viewUxHistorySearchDialog {

  public nameForm: UntypedFormGroup;
  myusername: string = "";


  constructor(@Inject(MAT_DIALOG_DATA,) public data: {
    note: Note;
  },
    public formBuilder: UntypedFormBuilder,
    public dialogRef: MatDialogRef<viewUxHistorySearchDialog>, private serviceHelper: ServiceHelperService) {
    this.nameForm = this.formBuilder.group({
      startdate: '',
      enddate: ''
    });
  }

  handleSave() {
    this.serviceHelper.uxHistorySearchFilter$.next(this.nameForm.value);

    this.dialogRef.close({
      save: true,
      note: this.data.note,
    });
  }
  handleClose() {
    this.dialogRef.close({
      save: false
    });
  }

}


@Component({
  selector: "add-email-dialog",
  templateUrl: "addEmailDialog.component.html",
})
export class AddEmailDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public email: any) { }
}

@Component({
  selector: "add-call-dialog",
  templateUrl: "addCallDialog.component.html",
})
export class AddCallDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public call: any) { }
}

interface RefundPaymentInterface {
  type: string | undefined;
  payment: CommercePayment;
  order: CommerceOrder;
  transaction: CommerceTransaction;
  userInfo: { [userId: string]: User };
}

@Component({
  selector: "refund-payment-dialog",
  templateUrl: "refundPaymentDialog.component.html",
})
export class RefundPaymentDialog {
  systemUsername = "System";
  custom = custom;
  timeFormat = "yyyy-MM-dd hh:mm:ss aa";
  amount = 0;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: RefundPaymentInterface,
    public dialogRef: MatDialogRef<RefundPaymentDialog>
  ) { }

  formatTimestamp(timestamp) {
    if (!timestamp) {
      timestamp = 0;
    }

    return moment(timestamp).tz('America/New_York').format("YYYY-MM-DD HH:mm:ss")
  }

  onRefund() {
    this.data.type = "refund";
    this.dialogRef.close({
      ...this.data,
      amount: +this.amount.toFixed(2),
    });
  }

  onVoid() {
    this.data.type = "void";
    this.dialogRef.close({
      ...this.data,
      amount: +this.amount.toFixed(2),
    });
  }

  convertAdjustAmount(amount) {
    return -1 * Math.abs(amount);
  }

  isAdjustable() {
    return (
      this.amount &&
      this.data.payment.isAdjustable({
        code: this.data.payment.price.code,
        amount: this.convertAdjustAmount(this.amount),
      })
    );
  }

  isVoidable(): boolean {
    return this.data.payment.isVoidable();
  }

  handleChange(event) {
    event.target.value = this.amount.toFixed(2);
  }
}

interface BillingInfoInterface {
  commerceToken: CommerceToken;
  customerUxComposite: UxComposite;
  uxComposite: UxComposite;
}

@Component({
  selector: "billing-info-dialog",
  templateUrl: "billingInfoDialog.component.html",
})
export class BillingInfoDialog {
  creditCardInputHelper = new CreditCardInputHelper();

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: BillingInfoInterface,
    public dialogRef: MatDialogRef<BillingInfoDialog>,
    private snackBar: MatSnackBar
  ) {
    this.creditCardInputHelper.setDummyAddress(this.data.customerUxComposite);
  }

  validateCardInfo() {
    if (this.creditCardInputHelper.isValidBillingInfo()) {
      this.dialogRef.close(this.creditCardInputHelper);
    } else {
      this.snackBar.open("Billing Card Information is incorrect", "error", {
        duration: 5000,
      });
    }
  }
}

@Component({
  selector: "view-note-dialog",
  templateUrl: "viewNoteDialog.component.html",
})
export class ViewNoteDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    note: Note;
  },
    public dialogRef: MatDialogRef<RefundPaymentDialog>) { }
    
  handleSave() {
    this.dialogRef.close({
      save: true,
      note:this.data.note,
    });
  }
}
@Component({
  selector: "view-attachment-dialog",
  templateUrl: "viewAttachmentDialog.component.html",
  styleUrls: ['./viewAttachmentDialog.component.scss'],
})
export class ViewAttachmentDialog {
  constructor(@Inject(MAT_DIALOG_DATA,) public data: {
    files;
    id,
    attachments
    commerceContentId
  },
  public dialogRef: MatDialogRef<ViewAttachmentDialog>, private serviceHelperService: ServiceHelperService,) { }
  files = [];
  getFiless: Array<File> = [];
  showFiless= [];
  showAttList: boolean = false;
  hideAttList: boolean = false;
  filesToUpload: Array<File> = [];
  attachmentNumber: number = 0;

  fileObject = {
    name: '',
    date: ""
  }

  fileSizeAlert: boolean = false;
  progress: number = 0;
  f = new UntypedFormGroup({
    file: new UntypedFormControl("")
  })
  progressShow: boolean = false;
  unHideShow: boolean = false;
  attachmentItemLists = [];
  commerceContentUniqueId: string = "";
  currentDate = new Date();
  downloadLink = "#"
  downloadContentType: string = "";
  @ViewChild('downloadButton') downloadButton: ElementRef;
  storeBase64: string;
  setFileName = "attachmetns";
  @Output() onDocumentSave = new EventEmitter()

  ngOnInit() {
    this.attachmentItemLists = this.data.attachments?.length > 0 ? this.data.attachments.filter((res: any) => {
      if (res.fileName.length) {
        return true;
      }
    }) : [];
    this.commerceContentUniqueId = this.data.commerceContentId;
  }

  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(
        event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex,
      );
    }
  }

  onSave(event) {
    try {
      this.unHideShow = false;

      const maxAllowedSize = 20 * 1024 * 1024;

      const files: File[] = event?.target?.files?.length > 0 ? Array.from(event?.target?.files) : [];

      const filesSize = files.reduce<number>((acc, curr) => {
        const size: number = +(curr?.['size'] ?? 0);

        return acc + size;
      }, 0);

      if (files?.length > 0 && filesSize <= maxAllowedSize) {
        files.forEach((file) => {
          this.getFiless.push(file);

          if (this.getFiless.length) {
            this.hideAttList = true;

            this.showAttList = false;
          }

          this.fileObject.name = file.name;

          this.fileSizeAlert = false;

          this.files.push(this.fileObject);
        });
      } else {
        this.fileSizeAlert = true;
      }
    } catch (error) {
      LogUtils.error(error)
    }
  }


  onDelAttachment(i: string) {
    this.attachmentItemLists = this.attachmentItemLists.filter((f: any) => f.attachmentUniqueId !==i);
    const selectQuery = { attachmentId: i, commerceContentId: this.commerceContentUniqueId }
    this.serviceHelperService.commerceService.deleteAttachment(selectQuery).subscribe((res: HttpEvent<any>) => {
      this.onDocumentSave.emit()
      this.dialogRef.close()
    })
  }

  removeAttachment(name) {
    this.getFiless = this.getFiless.filter((r) => r.name !==name);
  }
  onDownloadAttachment(fileId: any, contentType) {
    const selectQuery = { attachmentId: fileId, contentType: contentType }
    this.serviceHelperService.commerceService.downloadAttachment(selectQuery).subscribe(async (res) => {
      let binaryData = [];
      binaryData.push(res);
      const blob = new Blob([binaryData[0]], { type: `${res.type}` });
      let base64Url = await new Promise<string>((resolve, reject) => {
        let reader = new FileReader();
        reader.onload = _e => resolve(reader.result as string);
        reader.onerror = _e => reject(reader.error);
        reader.onabort = _e => reject(new Error("Read aborted"));
        reader.readAsDataURL(blob);
      });
      this.storeBase64 = base64Url;
      const link = document.createElement("a");
      link.href = base64Url;
      link.download = `${this.setFileName}` + `${res.type}`
      link.click();
    })
  }
  onGetfiles() {
    this.serviceHelperService.commerceService.getFiles().subscribe((res: any) => {
    })
  }

  onSubmit() {
    this.progressShow = true;
    this.unHideShow = true;
    
    this.hideAttList = false
    this.showFiless=this.getFiless;
    if (this.showFiless.length) {
      this.hideAttList = false;
      this.showAttList = true;
    }
    
    
    for (let i = 0; i <= 90; i++) {
      setTimeout(() => {
        this.progress = i + 10;
      }, 500);
    }
    setTimeout(() => {
      this.progressShow = false;
      this.unHideShow = false;
      this.progress = 0;
      this.showFiless = [];
    if (!this.showFiless.length) {
      this.showAttList = false;
    }
    }, 2000)
    

    const formData: any = new FormData();
    for (let i = 0; i < this.getFiless.length; i++) {
      formData.append("file", this.getFiless[i]);
    }
    formData.append("comConId", JSON.stringify(this.data.id));
    this.serviceHelperService.commerceService.addAttachment(formData).subscribe((res: HttpEvent<any>) => {
      this.onDocumentSave.emit()
      this.dialogRef.close()
    })
  }
  handleSave() {
  }
}


@Component({
  selector: "chargeback-dialog",
  templateUrl: "chargebackDialog.component.html"
})
export class ChargebackDialog {
  constructor(@Inject(MAT_DIALOG_DATA,) 
    public data: {
      payment, 
      outputData
    },
    public dialogRef: MatDialogRef<ChargebackDialog>, 
    private serviceHelperService: ServiceHelperService,
    private sanitizer: DomSanitizer,
  ) { }
 
  chagebackHTML: SafeHtml;

  ngOnInit() {
    this.chagebackHTML = this.sanitizer.bypassSecurityTrustHtml(this.data.outputData);   
  }

  handleClose() {
    this.dialogRef.close();
  }

}


// search dialog
@Component({
  selector: "view-search-dialog",
  templateUrl: "viewSearchDialog.component.html",
})
export class ViewSearchDialog {

  public nameForm:UntypedFormGroup;
  keywords = "";
  searchedCsrs: any[] = [];
  searchSubject = new Subject();
  selectedOwner: any = null;

  @Output() onAssigned = new EventEmitter();
  @Output() onCdpSaved = new EventEmitter();

  constructor(@Inject(MAT_DIALOG_DATA,) public data: {
    note: Note;
  },
  public formBuilder: UntypedFormBuilder,
  public dialogRef: MatDialogRef<ViewSearchDialog>,private serviceHelper:ServiceHelperService) {
    this.nameForm = this.formBuilder.group({
      name: '',
      csrowner:'',
      from:'',
      to:'',
      startdate:'',
      enddate:'',
      subject:'',
      body:''
    });
    this.searchSubject.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe((value) => {
        this.fetchCsrs(value);
      });
  }

  displayFn(csr) {
    if (csr && csr.obj) {
      return csr.obj?.firstName + ' ' + csr.obj?.lastName;
    } else {
      return '';
    }
  }

  fetchCsrs(value) {
    return this.serviceHelper
      .jsonService
      .json(serverPaths.manageCsrFindCsr, { param: { keywords: this.keywords } })
      .then((res: any) => {
        const arr = res.docs;
        this.searchedCsrs = arr.filter(el => el.obj.status === 'active');
      })
      .catch(err => {
        LogUtils.info(err);
      });
  }

  selectOption(event) {
    this.selectedOwner = event.option.value.obj._id;
  }

  handleSave() {
      let filterFormData = {
      name: this.nameForm.value.name,
      csrowner: this.nameForm.value.csrowner.obj?.firstName == undefined && this.nameForm.value.csrowner.obj?.lastName == undefined? "": this.nameForm.value.csrowner.obj?.firstName + ' ' + this.nameForm.value.csrowner.obj?.lastName,
      from:this.nameForm.value.from,
      to: this.nameForm.value.to,
      startdate: this.nameForm.value.startdate,
      enddate:this.nameForm.value.enddate,
      subject: this.nameForm.value.subject,
      body: this.nameForm.value.body
    }

    this.serviceHelper.emailFilter$.next(filterFormData);

    this.dialogRef.close({
      save: true,
      note: this.data.note,
    });
  }

  handleClose() {
    this.dialogRef.close({
      save: false
    });
  }
}

@Component({
  selector: "add-offer-dialog",
  templateUrl: "addCommerceOfferDialog.component.html",
})
export class AddCommerceOfferDialog {
  commerceOfferId = '';
  selectedIndex = -1;

  constructor(@Inject(MAT_DIALOG_DATA) public data: {
    commerceOffers: CommerceOffer[],
    brandName: string,
  },
    public dialogRef: MatDialogRef<AddCommerceOfferDialog>) { }

  handleAddCommerceOffer() {
    this.dialogRef.close({
      save: true,
      commerceOfferId: this.commerceOfferId,
    });
  }

  handleChangeOfferId(value) {
    this.commerceOfferId = value;
    this.selectedIndex = this.data.commerceOffers.findIndex((offer) => offer._id === this.commerceOfferId);
  }

  getDuration(commerceOffer, trial = false) {
    if (!commerceOffer) {
      return '';
    }
    const prices = commerceOffer.tempClientSecured.prices;
    if (trial) {
      return prices[0][0]?.periodString;
    }
    return prices[1][0]?.periodString;
  }
}

@Component({
  selector: 'confirmation-dialog',
  templateUrl: 'confirmationDialog.component.html',
})
export class ConfirmationDialog {
  constructor(public dialogRef: MatDialogRef<ConfirmationDialog>) {}

  public confirmMessage: string;
}
