export var peopleSearchProductKeys = {
  nameSearch: "nameSearch",
  phoneSearch: "phoneSearch",
  indicatorSearch: "indicatorSearch",
  indicatorCountSearch: "indicatorCountSearch",
  inmateSearch: "inmateSearch",
  lifeEvents: "lifeEvents",
  sexOffender: "sexOffender",
  versium: "versium",
  pdf: "pdf",
  factFinder: "factFinder",
  removeInfo: "removeInfo",
};
