import {isArray, isNumber, isObject} from "util";
import {CommerceContent} from "../../models/commerce/commerceContent";
import {Raw} from "../../models/raw";
import {UxComposite} from "../../models/ux/uxComposite";
import {ArrayUtils} from "../../utils/arrayUtils";
import {LogUtils} from "../../utils/logUtils";
import {timeUtils} from "../../utils/timeUtils";
import {SearchedPerson} from "../models/peopleSearch/searchedPerson";
import {peopleSearchProductKeys} from "./peopleSearchProductKeys";

export class PeopleSearchContentHelper {
  public static ERROR = {
    general: "general",
    tooManyResult: "tooManyResult",
    matchNotFound: "matchNotFound",
  };
  public static COMMERCE_CONTENT_TYPE = "peopleSearch";
  public static COMMERCE_CONTENT_SUBTYPE = {
    candidate: "candidate",
  };
  public static QUESTION_TYPE = {
    person: "person",
    cityState: "cityState",
    relative: "relative",
    moreThanAge: "moreThanAge",
    general: "general",
    fcra: "fcra",
  };

  public static QUESTION_SUB_TYPE = {
    relativeInterested: "relativeInterested",
  };
  public static SORT_TYPE = {
    qualification: "qualification",
    name: "name",
    age: "age",
  };
  public static PROVIDERS = {
    fullContact: "FullContact",
  };

  getError(e) {
    if (e && e.data && e.data.error) {
      return e.data.error;
    }
  }

  isValid(commerceContent: CommerceContent): boolean {
    if (commerceContent) {
      let raw = commerceContent.getMainRaw();
      return raw && raw.tempClient.processed && raw.tempClient.processed.person;
    }

    return false;
  }

  getScore(uxComposite: UxComposite, type, flag) {
    let score = 0;
    try {
      let rule = uxComposite.get("comp.name-search.rule.score");
      if (rule) {
        if (flag) {
          score = rule[type].positive;
        } else {
          score = rule[type].negative;
        }
      }
    } catch (e) {
      LogUtils.error(e, type);
    }

    return score;
  }

  getVerifiedLimit(uxComposite: UxComposite) {

    let limit = uxComposite.get("comp.name-search.rule.verified.limit");
    if (!isNumber(limit)) {
      limit = 0;
    }

    return limit;
  }

  processResult(uxComposite: UxComposite, commerceContent: CommerceContent) {
    let cityStates: any = {};
    let relatives: any = {};
    let ages = [];
    let searchedPersons = [];

    if (this.isValid(commerceContent)) {
      try {
        uxComposite.setCode("contentInfo", commerceContent.content[0].contentInfo);
      } catch (e) {
        LogUtils.error(e);
      }
      searchedPersons = this.getSearchedPersons(commerceContent);
    }

    searchedPersons.forEach((searchedPerson) => {
      this.processQualification(uxComposite, searchedPerson, commerceContent.inputs);
      if (searchedPerson.qualified) {
        let currentCityStates = searchedPerson.getCityStates();
        if (currentCityStates) {
          currentCityStates.forEach((cityState) => {
            if (!this.hasAnswer(commerceContent, PeopleSearchContentHelper.QUESTION_TYPE.cityState, cityState)) {
              if (cityStates[cityState]) {
                cityStates[cityState]++;
              } else {
                cityStates[cityState] = 1;
              }
            }
          });
        }

        let currentRelatives = searchedPerson.getRelatives();
        if (currentRelatives) {
          currentRelatives.forEach((relative) => {
            if (!this.hasAnswer(commerceContent, PeopleSearchContentHelper.QUESTION_TYPE.relative, relative)) {
              if (relatives[relative]) {
                relatives[relative]++;
              } else {
                relatives[relative] = 1;
              }
            }
          });
        }
      }

      if (searchedPerson.age) {
        ages.push(searchedPerson.age);
      }

    });

    this.setVerifiedAndSortByQualification(uxComposite, commerceContent);
    this.sortBy(commerceContent, PeopleSearchContentHelper.SORT_TYPE.qualification);

    let cityStateArray = ArrayUtils.toArray(cityStates).sort((a, b) => {
      return b.value - a.value;
    });

    let relativeArray = ArrayUtils.toArray(relatives).sort((a, b) => {
      return b.value - a.value;
    });

    ages = ages.sort((a, b) => {
      return b - a;
    });
    commerceContent.tempClient.questions = [
      {type: PeopleSearchContentHelper.QUESTION_TYPE.cityState, choices: cityStateArray},
      {type: PeopleSearchContentHelper.QUESTION_TYPE.relative, choices: relativeArray},
      {type: PeopleSearchContentHelper.QUESTION_TYPE.moreThanAge, choices: ages},
      {type: PeopleSearchContentHelper.QUESTION_TYPE.fcra, choices: [true]}
    ];
  }

  processQualification(uxComposite: UxComposite, searchedPerson: SearchedPerson, inputs) {
    let qualified = true;
    let qualifications = [];
    let totalScore = 0;

    if (searchedPerson && inputs) {
      inputs.forEach((input) => {
        let value = input.split(":");
        let score = 0;

        let notKnown = value[0].substring(0, 1) === "?";
        if (notKnown === false) {
          let negative = value[0].substring(0, 1) === "!";
          let regExpValue = value[0];
          if (negative) {
            regExpValue = regExpValue.substr(1);
          }
          let regExp = new RegExp("^!?" + regExpValue + "$");
          let result: boolean = null;
          let type;

          if (regExp.test(PeopleSearchContentHelper.QUESTION_TYPE.person)) {
            type = PeopleSearchContentHelper.QUESTION_TYPE.person;
            result = searchedPerson.extId === value[1];
          } else if (regExp.test(PeopleSearchContentHelper.QUESTION_TYPE.cityState)) {
            type = PeopleSearchContentHelper.QUESTION_TYPE.cityState;
            result = searchedPerson.hasCityStates(value[1].split("|"));
          } else if (regExp.test(PeopleSearchContentHelper.QUESTION_TYPE.relative)) {
            type = PeopleSearchContentHelper.QUESTION_TYPE.relative;
            result = searchedPerson.hasRelatives(value[1].split("|"));
          } else if (regExp.test(PeopleSearchContentHelper.QUESTION_TYPE.moreThanAge)) {
            if (searchedPerson.age) {
              type = PeopleSearchContentHelper.QUESTION_TYPE.moreThanAge;
              // Age - 2 since this is more than age, and people does not remember birth day correctly. for safe reason decreasing age.
              let age = parseInt(value[1]);
              if (negative) {
                age += 2;
              } else {
                age -= 2;
              }

              result = isNumber(searchedPerson.age) && searchedPerson.moreThanAge(age);
            }
          }

          if (result !== null) {
            score = this.getScore(uxComposite, type, !negative);

            if (negative === true) {
              result = !result;
            }

            if (result === true) {
              qualifications.push(input);
              totalScore += score;
            } else {
              qualified = false;
            }
          }
        }
      });
    }

    searchedPerson.qualified = qualified;
    searchedPerson.qualifiedScore = totalScore;
    searchedPerson.qualifications = qualifications;
  }

  sortBy(commerceContent: CommerceContent, type, ascending = true) {
    if (type === PeopleSearchContentHelper.SORT_TYPE.qualification) {
      return this.sortByQualifications(commerceContent, ascending);
    } else if (type === PeopleSearchContentHelper.SORT_TYPE.age) {
      return this.sortByAge(commerceContent, ascending);
    } else if (type === PeopleSearchContentHelper.SORT_TYPE.name) {
      return this.sortByName(commerceContent, ascending);
    }
  }

  setVerifiedAndSortByQualification(uxComposite: UxComposite, commerceContent: CommerceContent) {
    if (this.isValid(commerceContent)) {
      let fname;
      let lname;
      try {
        if (commerceContent.content[0].contentInfo.fname) {
          fname = commerceContent.content[0].contentInfo.fname.toLowerCase().trim();
        }
        if (commerceContent.content[0].contentInfo.lname) {
          lname = commerceContent.content[0].contentInfo.lname.toLowerCase().trim();
        }
      } catch (e) {
        LogUtils.error(e);
      }

      let limit = this.getVerifiedLimit(uxComposite);
      this.sortBy(commerceContent, PeopleSearchContentHelper.SORT_TYPE.qualification);
      commerceContent.getMainRaw().tempClient.processed.person.forEach((person: SearchedPerson) => {
        if (limit > 0 && fname && lname && person.fName && person.lName && person.fName && person.lName &&
          fname === person.fName.toLowerCase() && lname === person.lName.toLowerCase()) {

          person.tempClient.verified = true;
          limit--;
        } else {
          person.tempClient.verified = false;
        }
      });
    }
  }

  sortByQualifications(commerceContent: CommerceContent, ascending = true) {
    if (this.isValid(commerceContent)) {
      commerceContent.getMainRaw().tempClient.processed.person.sort((a: SearchedPerson, b: SearchedPerson) => {
        if (!ascending) {
          const t = a;
          a = b;
          b = t;
        }

        let score = b.qualifiedScore - a.qualifiedScore;
        return score !== 0 ? score : a.originalOrder - b.originalOrder;
      });
    }
  }

  sortByName(commerceContent: CommerceContent, ascending = true) {
    if (this.isValid(commerceContent)) {
      commerceContent.getMainRaw().tempClient.processed.person.sort((a: SearchedPerson, b: SearchedPerson) => {
        if (!ascending) {
          const t = a;
          a = b;
          b = t;
        }

        let aName = a.fName + (a.mName ? a.mName : "") + a.lName;
        let bName = b.fName + (b.mName ? b.mName : "") + b.lName;
        if (aName > bName) {
          return 1;
        } else if (bName > aName) {
          return -1;
        } else {
          return a.originalOrder - b.originalOrder;
        }
      });
    }
  }

  sortByAge(commerceContent: CommerceContent, ascending = true) {
    this.sortByQualifications(commerceContent);
    if (this.isValid(commerceContent)) {
      let index = commerceContent.getMainRaw().tempClient.processed.person.map(el=>el?.age).indexOf('??');
      if (commerceContent.getMainRaw().tempClient.processed.person.map(el=>el?.age).includes('??')) {
        var removedEl = commerceContent?.getMainRaw()?.tempClient?.processed?.person?.splice(index, 1) || [];
      }
      commerceContent.getMainRaw().tempClient.processed.person.sort((a: SearchedPerson, b: SearchedPerson) => {
        if (!ascending) {
          const t = a;
          a = b;
          b = t;
        }

        if (!a.age) {
          return 1;
        }
        if (!b.age) {
          return -1;
        }
        let score = a.age - b.age;
        return score !== 0 ? score : a.originalOrder - b.originalOrder;
      });
    }
    commerceContent.getMainRaw().tempClient.processed.person = [...commerceContent?.getMainRaw()?.tempClient?.processed?.person, removedEl]
  }

  hasQuestionTypeInput(commerceContent: CommerceContent, type): boolean {
    let flag = false;
    let regExp = new RegExp("^[!?]?" + type + ":");
    commerceContent.inputs.some((input) => {
      if (regExp.test(input)) {
        flag = true;
        return true;
      }
    });

    return flag;
  }

  shapeQuestion(uxComposite: UxComposite, question) {
    let shaped = [];
    let placeholderQuestions = [];
    let placeholders = uxComposite.get("comp.name-search.rule.question.location.placeholder");
    if (placeholders && isArray(placeholders)) {
      placeholders.reverse().forEach((value) => {
        placeholderQuestions.push({key: value, value: 0});
      });
    }
    if (question.type === PeopleSearchContentHelper.QUESTION_TYPE.cityState ||
      question.type === PeopleSearchContentHelper.QUESTION_TYPE.relative) {
      for (let i = 0; i < 3; i++) {
        if (question.choices[i]) {
          shaped.push(question.choices[i]);
        } else if (placeholderQuestions.length > 0) {
          if (question.type === PeopleSearchContentHelper.QUESTION_TYPE.cityState) {
            shaped.push(placeholderQuestions.pop());
          }
        }
      }
    } else if (question.type === PeopleSearchContentHelper.QUESTION_TYPE.moreThanAge) {
      shaped.push(question.choices[Math.floor(question.choices.length / 2)]);
    } else if (question.type === PeopleSearchContentHelper.QUESTION_TYPE.fcra) {
      shaped.push([true]);
    }

    return shaped;
  }

  shapeAnswer(uxComposite: UxComposite, question, answer): string {
    let shaped = this.shapeQuestion(uxComposite, question);
    let choiceValue = "";
    shaped.forEach((choice, i) => {
      if (i !== 0) {
        choiceValue += "|";
      }
      if (isObject(choice)) {
        choiceValue += choice.key;
      } else {
        choiceValue += choice;
      }
    });

    let input = `${question.type}:${choiceValue}`;

    if (answer === false) {
      input = "!" + input;
    } else if (answer === null) {
      input = "?" + input;
    }

    return input;
  }

  shapeGeneralAnswer(questionKey, answer): string {
    let input = `${PeopleSearchContentHelper.QUESTION_TYPE.general}:${questionKey}:${answer}`;
    return input;
  }

  shapePersonIdAnswer(personId, answer): string {
    let input = `${PeopleSearchContentHelper.QUESTION_TYPE.person}:${personId}`;

    if (answer === false) {
      input = "!" + input;
    } else if (answer === null) {
      input = "?" + input;
    }

    return input;
  }

  hasAnswer(commerceContent: CommerceContent, type, question): boolean {
    let flag = false;
    let regExp = new RegExp("^[!?]?" + type + ":.*(" + question + ")");
    commerceContent.inputs.some((input) => {
      if (regExp.test(input)) {
        flag = true;
        return true;
      }
    });

    return flag;
  }

  addFullStringDob(person: SearchedPerson) {
    person.tempClient.dob = timeUtils.formatStringDate(person.dob);
  }

  addFullName(person: SearchedPerson) {
    person.tempClient.fullName = `${person.fName} ${person.mName ? person.mName + ' ' : ''}${person.lName}`;
  }

  chooseCandidateSearchObject(commerceContent: CommerceContent) {
    let candidateSearchObject = null;
    if (commerceContent && commerceContent.productKey === peopleSearchProductKeys.phoneSearch) {

      let selectedPhone;
      if (commerceContent.content &&
        commerceContent.content[0] &&
        commerceContent.content[0].contentInfo) {

        if (commerceContent.content[0].contentInfo.phone) {
          selectedPhone = commerceContent.content[0].contentInfo.phone;
        }
        if (commerceContent.content[0].contentInfo.searchContent &&
          commerceContent.content[0].contentInfo.searchContent[0] &&
          commerceContent.content[0].contentInfo.searchContent[0].contentInfo &&
          commerceContent.content[0].contentInfo.searchContent[0].contentInfo.phone) {
          selectedPhone = commerceContent.content[0].contentInfo.searchContent[0].contentInfo.phone;
        }
      }

      if (selectedPhone) {
        let phone = selectedPhone.replace(/(^1|[^0-9])/g, "");
        let raw = commerceContent.getMainRaw();
        if (phone && raw &&
          raw.tempClient &&
          raw.tempClient.processed &&
          raw.tempClient.processed.person) {
          raw.tempClient.processed.person.some((person) => {
            if (person.phones) {
              person.phones.some((eachPhone, i) => {
                if (eachPhone && eachPhone.number) {
                  let eachPhoneNumber = eachPhone.number.replace(/(^1|[^0-9])/g, "");
                  if (eachPhoneNumber) {
                    if (phone.startsWith(eachPhoneNumber)) {
                      candidateSearchObject = person;
                      person.tempClient.selectedPhone = eachPhone;
                      person.tempClient.selectedPhoneNumber = selectedPhone;
                    }
                  }
                }
                if (candidateSearchObject) {
                  return true;
                }
              });
            }
            if (candidateSearchObject) {
              return true;
            }
          });

          if (!candidateSearchObject) {
            // if there are no phone information. just choose first person
            try {
              candidateSearchObject = commerceContent.getMainRaw().tempClient.processed.person[0];
              commerceContent.getMainRaw().tempClient.processed.person[0].tempClient.selectedPhoneNumber = selectedPhone;
            } catch (e) {
              // consome. no result at all
            }
          }
        }

      }
      LogUtils.debug(candidateSearchObject);
      return candidateSearchObject;
    }
  }

  getParentExtId(raw: Raw) {
    return raw && raw.meta && raw.meta.parentExtId;
  }

  getSearchedPersons(commerceContent: CommerceContent) {
    let searchedPersons: SearchedPerson[] = [];
    commerceContent.getMainRaw().tempClient.processed.person.forEach((searchedPerson, i) => {
      if (!(searchedPerson instanceof SearchedPerson)) {
        searchedPerson = new SearchedPerson(searchedPerson);
        commerceContent.getMainRaw().tempClient.processed.person[i] = searchedPerson;
      }
      this.addFullStringDob(commerceContent.getMainRaw().tempClient.processed.person[i]);
      this.addFullName(commerceContent.getMainRaw().tempClient.processed.person[i]);
      searchedPerson.photos = searchedPerson.getUniquePhotos();
      searchedPersons.push(searchedPerson);
    });
    return searchedPersons;
  }
}

export const peopleSearchContentHelper = new PeopleSearchContentHelper();
