<style>
  #confirmationDialog {
    padding: 15px;
    min-width: 300px;
  }

  #confirmationDialog > h2 {
    margin: 0;
    margin-bottom: 50px;
    font-weight: normal;
    font-size: 18px;
  }

  #confirmationDialog > div.buttons {
    display: flex;
    justify-content: space-between;
  }

  #confirmationDialog > div.buttons > button {
    padding: 8px 10px;
    min-width: 90px;
    cursor: pointer;
  }

  #confirmationDialog > div.buttons > button.cancel {
    border: 1px solid red;
  }

  #confirmationDialog > div.buttons > button.confirm {
    border: 1px solid #e2e2e2;
  }
</style>

<div id="confirmationDialog">
  <h2 md-dialog-title>{{confirmMessage}}</h2>
  <div class="buttons">
    <button class="mdc-button mdc-button--raised mat-mdc-raised-button mat-mdc-button-base" (click)="dialogRef.close(false)">Cancel</button>
    <button class="mdc-button mdc-button--raised mat-mdc-raised-button mat-primary mat-mdc-button-base" (click)="dialogRef.close(true)">Confirm</button>
  </div>
</div>