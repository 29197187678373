import {IndexField} from "../../decorators/database/indexField";
import {ModelBase} from "../modelBase";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {UxComponent} from "./uxComponent";
import {ReferenceField} from "../../decorators/database/referenceField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {NoCyclicField} from "../../decorators/database/noCyclicField";
import {LogUtils} from "../../utils/logUtils";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {TransientField} from "../../decorators/database/transientField";

@CollectionClass({name: "uxComponentCollections"})
export class UxComponentCollection extends ModelBase<UxComponentCollection> {
    @IndexField()
    public name;
    public description;

    @ReferenceField(UxComponent)
    @ForeignKeyField(UxComponent)
    public uxComponentIds: string[] = [];

    @NoCyclicField
    @ReferenceField(UxComponentCollection)
    @ForeignKeyField(UxComponentCollection)
    public childUxccIds: string[] = [];

    @TransientField
    @ForeignObjectField("uxComponentIds")
    public uxComponents: UxComponent[];

    @TransientField
    @ForeignObjectField("childUxccIds")
    public childUxccs: UxComponentCollection[];

    constructor(doc?, draftFlag?: boolean) {
        super(doc, draftFlag);
        this.init(doc, this.draftFlag);
    }

    getChildUxCCById(id: string): UxComponentCollection {
        let obj = null;
        try {
            if (this.childUxccs) {
                this.childUxccs.some((obj2) => {
                    if (obj2._id === id) {
                        obj = obj2;
                        return true;
                    }
                });
            }
        } catch (e) {
            LogUtils.error(e, this);
        }

        return obj;
    }

    getUxCompById(id: string): UxComponent {
        let obj = null;
        try {
            if (this.uxComponents) {
                this.uxComponents.some((obj2) => {
                    if (obj2._id === id) {
                        obj = obj2;
                        return true;
                    }
                });
            }
        } catch (e) {
            LogUtils.error(e, this);
        }

        return obj;
    }
}

