import {ModelBase} from "../modelBase";
import {IndexField} from "../../decorators/database/indexField";
import {UxLayout} from "./uxLayout";
import {ForeignKeyField} from "../../decorators/database/foreignKeyField";
import {ReferenceField} from "../../decorators/database/referenceField";
import {ForeignObjectField} from "../../decorators/database/foreignObjectField";
import {LogUtils} from "../../utils/logUtils";
import {CollectionClass} from "../../decorators/database/collectionClass";
import {TransientField} from "../../decorators/database/transientField";

@CollectionClass({name: "uxConfigs"})
export class UxConfig extends ModelBase<UxConfig> {
  public static SLOT_TYPES = {
    default: "default",
    cascade: "manual",
  };
  public static SPLIT_TYPES = {
    random: "random",
  };

  @IndexField()
  public name;
  public description;

  @ReferenceField(UxLayout)
  @ForeignKeyField(UxLayout)
  public uxLayoutIds: string[] = [];

  @TransientField
  @ForeignObjectField("uxLayoutIds")
  public uxLayouts: UxLayout[];

  splitType: string;
  slotTypes: { [id: string]: { slotType: string, weight: number, desc: string } } = {};

  constructor(doc?, draftFlag?: boolean) {
    super(doc, draftFlag);
    this.init(doc, this.draftFlag);
  }

  getUxLayoutById(id: string): UxLayout {
    let obj = null;
    try {
      if (this.uxLayouts) {
        this.uxLayouts.some((obj2) => {
          if (obj2._id === id) {
            obj = obj2;
            return true;
          }
        });
      }
    } catch (e) {
      LogUtils.error(e, this);
    }

    return obj;
  }

  setSlotType(uxlId, key, value) {
    if (!this.slotTypes[uxlId]) {
      let slotType: any = {};
      this.slotTypes[uxlId] = slotType;
    }
    this.slotTypes[uxlId][key] = value;
  }

  removeRemoved() {

    if (this.uxLayoutIds) {
      const newUxLayoutIds = [];

      this.uxLayoutIds.forEach((id) => {
        let flag = true;
        if (this.uxLayouts && this.uxLayouts.length > 0) {
          this.uxLayouts.some((uxLayout) => {
            if (uxLayout._id === id) {
              if (uxLayout.status !== ModelBase.STATUS.active) {
                flag = false;
              }
              return true;
            }
          });
        }

        if (flag) {
          newUxLayoutIds.push(id);
        }
      });

      this.uxLayoutIds = newUxLayoutIds;
    }
  }

  removeUnusedSlotTypes() {
    let unusedKeys = [];
    Object.keys(this.slotTypes).forEach((key) => {
      let remove = true;
      this.uxLayoutIds.some((uxlId) => {
        if (uxlId === key) {
          remove = false;
          return true;
        }
      });

      if (remove) {
        unusedKeys.push(key);
      }
    });

    unusedKeys.forEach((key) => {
      this.slotTypes[key] = undefined;
    });
  }
}

